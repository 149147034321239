const actionTypes = {
	BOOT_INIT: "BOOT_INIT",
	BOOT_SUCCESS: "BOOT_SUCCESS",
	BOOT_ERROR: "BOOT_ERROR",

	MODAL_OPEN: "MODAL_SHOW",
	MODAL_CLOSE: "MODAL_CLOSE",

	REDIRECT: "REDIRECT",
	REDIRECT_RESET: "REDIRECT_RESET",

	SET_MAYOR: "SET_MAYOR",
	SET_ACEPTO: "SET_ACEPTO",

	GET_USER_INIT: "GET_USER_INIT",
	GET_USER_SUCCESS: "GET_USER_SUCCESS",
	GET_USER_ERROR: "GET_USER_ERROR",

	SET_USER_DATA: "SET_USER_DATA",

	USER_SAVE_INIT: "USER_SAVE_INIT",
	USER_SAVE_SUCCESS: "USER_SAVE_SUCCESS",
	USER_SAVE_ERROR: "USER_SAVE_ERROR",

	PARTICIPAR_INIT: "PARTICIPAR_INIT",
	PARTICIPAR_SUCCESS: "PARTICIPAR_SUCCESS",
	PARTICIPAR_ERROR: "PARTICIPAR_ERROR",

	SET_PARTICIPAR_DATA: "SET_PARTICIPAR_DATA",
};

export default actionTypes;
