import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

let componseEnhancers = compose;
try {
	if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
		componseEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
	}
} catch {}

const store = (preloadedState) => {
	const store = createStore(
		reducers,
		preloadedState,
		componseEnhancers(applyMiddleware(sagaMiddleware))
	);
	sagaMiddleware.run(rootSaga);
	return store;
};

export default store;