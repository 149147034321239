import actionTypes from "../actionTypes";
import { put, call } from "redux-saga/effects";
import API from "../../classes/api";

export function* processBootInit(action) {
	try {
		const boot = yield call(API.getBoot, action.payload);
		yield put({
			type: actionTypes.BOOT_SUCCESS,
			payload: boot,
		});
	} catch (error) {
		yield put({
			type: actionTypes.BOOT_ERROR,
		});
	}
}

export function* processGetUserInit(action) {
	try {
		const response = yield call(API.getUser, action.payload);
		yield put({
			type: actionTypes.GET_USER_SUCCESS,
			payload: response,
		});
	} catch (error) {
		yield put({
			type: actionTypes.GET_USER_ERROR,
			payload: error,
		});
	}
}

export function* processUserSaveInit(action) {
	try {
		const response = yield call(API.saveUser, action.payload);
		yield put({
			type: actionTypes.USER_SAVE_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.REDIRECT,
			payload: { to: "/participate" },
		});
	} catch (error) {
		yield put({
			type: actionTypes.USER_SAVE_ERROR,
			payload: error,
		});
	}
}

export function* processParticiparInit(action) {
	try {
		const response = yield call(API.participar, action.payload);
		yield put({
			type: actionTypes.PARTICIPAR_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.REDIRECT,
			payload: { to: "/results" },
		});
	} catch (error) {
		yield put({
			type: actionTypes.PARTICIPAR_ERROR,
			payload: error,
		});
	}
}
