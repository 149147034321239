import actionTypes from "../actionTypes";

const initialState = {
	boot: {},
	bootLoaded: false,
	modal: {
		title: "",
		body: "",
		open: false,
	},
	loading: false,
	network: null,
	mayor: null,
	acepto: false,
	usuario: {},
	errors: {
		userinfo: [],
		participar: [],
	},
	codigo: null,
	redirectTo: null,
	redirectOptions: null,
};

// const initialState = {
// 	boot: {},
// 	bootLoaded: false,
// 	modal: {
// 		title: "",
// 		body: "",
// 		open: false,
// 	},
// 	loading: false,
// 	network: null,
// 	mayor: true,
// 	acepto: true,
// 	usuario: {
// 		dni: "23505952",
// 	},
// 	errors: {
// 		userinfo: [],
// 		participar: [],
// 	},
// 	codigo: null,
// 	redirectTo: null,
// 	redirectOptions: null,
// };

const appReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		// app boot

		case actionTypes.BOOT_INIT:
			return {
				...state,
				loading: true,
				network: null,
			};

		case actionTypes.BOOT_SUCCESS:
			return {
				...state,
				boot: payload,
				bootLoaded: true,
				loading: false,
				network: true,
			};

		case actionTypes.BOOT_ERROR:
			return {
				...state,
				loading: false,
				network: false,
			};

		// app redirect

		case actionTypes.REDIRECT:
			return {
				...state,
				redirectTo: payload.to,
				redirectOptions: payload.options,
			};

		case actionTypes.REDIRECT_RESET:
			return {
				...state,
				redirectTo: initialState.redirectTo,
				redirectOptions: initialState.redirectOptions,
			};

		// mayor & acepto

		case actionTypes.SET_MAYOR:
			return {
				...state,
				mayor: payload,
			};

		case actionTypes.SET_ACEPTO:
			return {
				...state,
				acepto: payload,
			};

		// app modal

		case actionTypes.MODAL_OPEN:
			return {
				...state,
				modal: {
					...state.modal,
					...payload,
					open: true,
				},
			};

		case actionTypes.MODAL_CLOSE:
			return {
				...state,
				modal: initialState.modal,
			};

		// usuario

		case actionTypes.GET_USER_INIT:
			return {
				...state,
				loading: true,
			};

		case actionTypes.GET_USER_SUCCESS:
			return {
				...state,
				usuario: payload.usuario,
				errors: {
					...state.errors,
					userinfo: initialState.errors.userinfo,
				},
				loading: false,
			};

		case actionTypes.GET_USER_ERROR:
			return {
				...state,
				usuario: {
					...initialState.usuario,
					dni: state.usuario?.dni,
				},
				errors: {
					...state.errors,
					userinfo: payload.errors,
				},
				loading: false,
			};

		case actionTypes.SET_USER_DATA:
			return {
				...state,
				usuario: {
					...state.usuario,
					[payload.field]: payload.value,
				},
			};

		case actionTypes.USER_SAVE_INIT:
			return {
				...state,
				loading: true,
			};

		case actionTypes.USER_SAVE_SUCCESS:
			return {
				...state,
				errors: {
					...state.errors,
					userinfo: initialState.errors.userinfo,
				},
				loading: false,
			};

		case actionTypes.USER_SAVE_ERROR:
			return {
				...state,
				errors: {
					...state.errors,
					userinfo: payload.errors,
				},
				loading: false,
			};

		case actionTypes.SET_PARTICIPAR_DATA:
			return {
				...state,
				[payload.field]: payload.value,
			};

		case actionTypes.PARTICIPAR_INIT:
			return {
				...state,
				loading: true,
			};

		case actionTypes.PARTICIPAR_SUCCESS:
			return {
				...state,
				errors: {
					...state.errors,
					participar: initialState.errors.userinfo,
				},
				codigo: initialState.codigo,
				usuario: {
					...state.usuario,
					ganador: payload.resultado.ganador,
					premio: payload.resultado.premio,
				},
				loading: false,
			};

		case actionTypes.PARTICIPAR_ERROR:
			return {
				...state,
				errors: {
					...state.errors,
					participar: payload.errors,
				},
				loading: false,
			};

		default:
			return state;
	}
};

export default appReducer;
