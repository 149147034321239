import actionTypes from "./actionTypes";
import { takeLatest, all } from "redux-saga/effects";
import * as appSagas from "./app/sagas";

function* rootSaga() {
	yield all([
		takeLatest(actionTypes.BOOT_INIT, appSagas.processBootInit),
		takeLatest(actionTypes.GET_USER_INIT, appSagas.processGetUserInit),
		takeLatest(actionTypes.USER_SAVE_INIT, appSagas.processUserSaveInit),
		takeLatest(actionTypes.PARTICIPAR_INIT, appSagas.processParticiparInit),
	]);
}

export default rootSaga;
