import axios from "axios";

// const apiHost = "http://localhost:11081/";
const apiHost = "https://api.alwayswinwithcmc.com/";
const apiEndpoint = `${apiHost}<lang>/api/`;

const apiBoot = `${apiEndpoint}boot/`;
const apiUser = `${apiEndpoint}user/`;
const apiParticipar = `${apiEndpoint}participar/`;

class API {
	static getBoot = (lang) => {
		console.log("API/getBoot");
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(apiBoot.replace("<lang>", lang), {
					timeout: 10000,
				});
				console.log("API/getBoot Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/getBoot Response ERROR", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getBoot Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getBoot Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getUser = ({ lang, estanco }) => {
		console.log("API/getUser", lang, estanco);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(apiUser.replace("<lang>", lang) + `?estanco=${estanco}`, {
					timeout: 10000,
				});
				const { payload } = response.data.data.response;
				console.log("API/getUser Response OK", payload);
				resolve(payload);
			} catch (error) {
				if (error.response) {
					const { payload } = error.response.data.data.response;
					console.log("API/getUser Response ERROR", payload);
					reject(payload);
				} else if (error.request) {
					console.log("API/getUser Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getUser Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static saveUser = ({ lang, data }) => {
		console.log("API/saveUser", lang, data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiUser.replace("<lang>", lang), data, {
					timeout: 10000,
				});
				const { payload } = response.data.data.response;
				console.log("API/saveUser Response OK", payload);
				resolve(payload);
			} catch (error) {
				if (error.response) {
					const { payload } = error.response.data.data.response;
					console.log("API/saveUser Response ERROR", payload);
					reject(payload);
				} else if (error.request) {
					console.log("API/saveUser Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/saveUser Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static participar = ({ lang, data }) => {
		console.log("API/participar", lang, data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(apiParticipar.replace("<lang>", lang), data, {
					timeout: 10000,
				});
				const { payload } = response.data.data.response;
				console.log("API/participar Response OK", payload);
				resolve(payload);
			} catch (error) {
				if (error.response) {
					const { payload } = error.response.data.data.response;
					console.log("API/participar Response ERROR", payload);
					reject(payload);
				} else if (error.request) {
					console.log("API/participar Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/participar Response ERROR", error);
					reject(null);
				}
			}
		});
	};
}

export default API;
